<template>
  <div class="colelDetails">
    <colel-card
        :title="$t('colelDetails.title')"
        :icon=" 'mdi-sign-real-estate'"
        :isLoading="isLoading"
    >
      <template v-slot:content>
        <v-form v-if="colelDetails" class="p-p-4" ref="form" lazy-validation>
		   <v-text-field
			   v-if="field !== 'address_description'"
			   v-model="colelDetails.full_name"
			   :label="$t(`colelDetails.owner`)"
			   disabled
			   @input="updateField(field, $event )"
		   ></v-text-field>
<!--          <v-select-->
<!--              @change="updateField('owner', $event.id)"-->
<!--              :error-messages="errors"-->
<!--              :label="owner"-->
<!--              item-text="full_name"-->
<!--              item-value="id"-->
<!--              :items="owners"-->
<!--              persistent-hint-->
<!--              return-object-->
<!--              single-line-->
<!--          ></v-select>-->

          <div v-for="(field, index) in fields" :key="index">
            <v-text-field
                v-if="field !== 'address_description'"
                v-model="colelDetails[field]"
                :label="$t(`colelDetails.${field}`)"
                required
                @input="updateField(field, $event )"
            ></v-text-field>
            <v-textarea
                v-else
                rows="1"
                auto-grow
                v-model="colelDetails[field]"
                :label="$t(`colelDetails.${field}`)"
                required
                @input="updateField(field, $event)"
            ></v-textarea>
          </div>
        </v-form>
      </template>
    </colel-card>
  </div>
</template>
<script>
import {getMinimalPersonsDetails} from './colelDetails'

const defaultColelDetails = {
  name: "יש להכניס שם",
  phone: "יש להכניס מספר טלפון",
  mailAddress: "יש להכניס כתובת למשלוח דואר",
  studyAddress: "יש להכניס כתובת מקום הלימוד",
};
export default {
  name: "colelDetails",
  components: {},
  props: {
    colelProp: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      isLoading: true,
      owners: null,
      owner: null,
      colelDetails: null,
      selectedOwner: null,
      fields: ["phone_number", "mail_address", "address_description"],
    };
  },
  mounted() {
    this.colelDetails = {
      full_name: `${this.colelProp.colel_details.owner.first_name} ${this.colelProp.colel_details.owner.last_name}`,
      phone_number: this.colelProp.colel_details.owner.phone_number,
      mail_address: this.colelProp.colel.mail_address,
      address_description: this.colelProp.colel.address_description,
    }
    this.isLoading = false;
    this.owner = `${this.colelProp.colel_details.owner.first_name} ${this.colelProp.colel_details.owner.last_name}`
  },
  methods: {
    updateField(key, value) {
      this.$emit("updateDetails", {key, value})
    },
    async fillOwners() {
      try {
        const {data} = await getMinimalPersonsDetails();
        this.owners = data
            .map(owner => {
              return {id: owner.id, full_name: `${owner.last_name} ${owner.first_name}`}
            })
      } catch (e) {
        console.log(e)
      }
    }
  },
  created() {
    this.fillOwners()
  },
};
</script>

<style scoped></style>
