import axios from "axios";
import {dateToServer} from "@/utils/dateFormat";
import constants from "@/constants";
import store from "@/store";

export async function postColelHoursRecommendation(colelToUpdate) {
    const suggestion = {
        colel_id: colelToUpdate.id,
        entity_type: 1,
        suggestion_type: 1,
        create_date: dateToServer(new Date()),
        new_entity: colelToUpdate,
        old_entity: null
    }
    const result = await axios.patch(`/suggestions/colel`, suggestion);
    return (result.status === 200);
}

export async function postPersonRecommendation(person) {
    const colel_id = store.state?.colel?.colel?.id
    const suggestion = {
        colel_id,
        entity_type: constants.PERSON_TYPE,
        suggestion_type: constants.SUGGESTION_TYPE_EDIT,
        create_date: dateToServer(new Date()),
        new_entity: person
    };
    return await axios.patch(`/suggestions/person`, suggestion);

    // return "cool"
}