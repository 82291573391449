<template >
  <div class="IntroColelSettings">
    <v-alert
	    v-if="colel.colel.special_message_content"
	    dismissible
	    elevation="7"
	    :color="colel.colel.message_color"
    >
      <div class="text-h6">
        {{ colel.colel.special_message_title }}
      </div >
      {{ colel.colel.special_message_content }}

    </v-alert >
    <div class="p-grid p-jc-center p-p-0">
      <div class="p-col p-p-0">
        <v-container
		   class="justify-center d-inline-flex p-p-0"
		   fluid
	   >
          <v-checkbox
			v-model="isChecked"
			:label="$t('introColelSettings.checkBox')"
			:color="this.$vuetify.theme.themes.light.primary.darken1"
		></v-checkbox >
        </v-container >
      </div >
    </div >
    <div class="p-grid p-jc-center p-pb-0 p-pt-2">
      <div class="p-grid p-jc-center p-ai-center p-col ">
        <v-btn
		   :disabled="!isChecked"
		   :color="this.$vuetify.theme.themes.light.primary.darken1"
		   @click="submit"
	   >
          {{ $t('introColelSettings.continue') }}
        </v-btn >
      </div >
    </div >
    <div class="p-pt-1 p-grid p-jc-center">
      <div class="p-p-4 p-col-12 p-md-6 p-lg-4">
        <div class="box">
          <colelHours
			:colelProp="colel"
			intro-show
			v-on:updateHours="updateHours"
		/>
        </div >
      </div >
      <div class="p-pt-4 p-p-4 p-col-12 p-md-6 p-lg-4">
        <div class="box">
          <colelDetails
			:colelProp="colel"
			v-on:updateDetails="updateDetails"
		/>
        </div >
      </div >
    </div >
  </div >
</template >

<script >
import colelHours from "./colelHours/colelHours.vue";
import colelDetails from "./colelDetails/colelDetails.vue";
import {postColelHoursRecommendation, postPersonRecommendation} from
      "./introColelSettings";
import {mapState, mapActions} from "vuex";
import constants from "@/constants";

export default {
  name: "IntroColelSettings",
  components: {
    colelHours,
    colelDetails
  },
  mounted() {
  },
  data() {
    return {
      isHoursChanged: false,
      isColelChanged: false,
      isChecked: false,
      hours: null,
      details: null
    };
  },
  methods: {
    // getColor(color) {
    //   [
    //
    //     {text: "אדום", value: "red"},
    //     {text: "כחול", value: "blue"},
    //     {text: "צהוב", value: "yellow"},
    //     {text: "ירוק", value: "green"}
    //
    //   ]
    // },
    ...mapActions(["setSubmitSettings"]),
    async submit() {
      try {

        if (this.isHoursChanged || this.isColelChanged) {
          let colelToUpdate = {...this.colel.colel, ...this.details};
          colelToUpdate.hours = this.hours;
          try {
            postColelHoursRecommendation(colelToUpdate);
            if (colelToUpdate.phone_number){
              const owner = this.colel.colel_details.owner
		    owner.phone_number = colelToUpdate.phone_number
              postPersonRecommendation(owner)
            }

            this.$toast.add({
                              severity: 'success',
                              summary: this.$t('suggestions.savedSuccessfully'),
                              life: constants.LIFE_TOAST
                            });

          } catch (e) {
            console.log(e)
            this.$toast.add({
                              severity: 'error',
                              summary: this.$t('suggestions.savedSuccessfully'),
                              life: constants.LIFE_TOAST
                            });
          }

        }
        localStorage.setItem(constants.SUBMIT_COLEL_SETTINGS, true);
        this.setSubmitSettings(true);
        this.$router.push("/presence");
      } catch (e) {
        console.log("error: ", e)
      }
    },
    updateHours(hours) {
      if (JSON.stringify(hours) !==
          JSON.stringify(this.colel.colel_details.hours)) {
        this.isHoursChanged = true;
        this.hours = hours
      } else {
        this.isHoursChanged = false
      }
    },
    updateDetails({key, value}) {
      if (this.colel.colel[key] !== value) {
        this.isColelChanged = true;
        this.details = {...this.details};
        this.details[key] = value;
      } else {
        this.isColelChanged = false
      }

    }
  },
  computed: {
    ...mapState(["colel"])
  }
};
</script >
